import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';

import { toAdmin } from 'store/router/actions';
import { services as storeServices } from 'store/store';

import { StyledAdminPage, StyledTitle } from './style';

import BookOfUncertificatedSecuritiesUpload from './BookOfUncertificatedSecuritiesUpload';
import PcfSimulation from './PcfSimulation';
import PcfOverride from './PcfOverride';
import PcfInfo from './PcfInfo';
import InverseInfo from './InverseInfo';
import TaskRunner from './TaskRunner/index';
import DocumentsUpload from './DocumentsUpload';
import InstrumentControls from './Controls/InstrumentControls';
import UserInvite from './UserInvite';
import AuditLogViewer from './AuditLogViewer';
import Rebalance from './Rebalance';
import DelegatedOrder from './DelegatedOrder';
import ManualTradingDeskOrder from './ManualTradingDeskOrder';
import LendingDesk from './LendingDesk';
import WalletUpdater from '../Settings/WalletUpdater';
import BorrowHistory from './BorrowHistory/';
import InterestProduct from './InterestProduct'; // new
import CollateralCheck from './CollateralCheck';
import ManualRebalance from './ManualRebalance';
import InverseRebalance from './InverseRebalance';
import SecuritiesLending from './SecuritiesLending';
import CreateEtp from './CreateEtp';
import UpdateEtpConstituents from './UpdateEtpConstituents';
import UpdateEtp from './UpdateEtp';
import ContactList from './ContactList';
import ProductLedger from 'pages/ProductLedger';

const mapStateToProps = state => ({
  tab: state.location.payload.tab,
  subtab: state.location.payload.subtab,
});

const mapDispatchToProps = dispatch => ({
  toTab: ({ tab, subtab }) => dispatch(toAdmin({ tab, subtab })),
});

const subtabMap = {
  'pcf-simulation': {
    title: 'PCF Simulation',
    TabComponent: PcfSimulation,
  },
  'pcf-override': {
    title: 'PCF Override',
    TabComponent: PcfOverride,
  },
  'pcf-info': {
    title: 'PCF Information',
    TabComponent: PcfInfo,
  },
  'inverse-pcf-info': {
    title: 'Inverse PCF Info',
    TabComponent: InverseInfo,
  },
  'upload-book': {
    title: 'Upload Book of Uncertificated Securities File',
    TabComponent: BookOfUncertificatedSecuritiesUpload,
  },
  'delegated-order': {
    title: 'Delegated Order',
    TabComponent: DelegatedOrder,
  },
  rebalance: {
    title: 'Rebalance',
    TabComponent: Rebalance,
  },
  lendingDesk: {
    title: 'Lending Desk',
    TabComponent: LendingDesk,
  },
  borrowHistory: {
    title: 'Borrow History',
    TabComponent: BorrowHistory,
  },
  collateralCheck: {
    title: 'Collateral Check',
    TabComponent: CollateralCheck,
  },
  manualRebalance: {
    title: 'Manual Inverse Rebalance',
    TabComponent: ManualRebalance,
  },
  'cumberland-order': {
    title: 'Manual Trading',
    TabComponent: ManualTradingDeskOrder,
  },
  'create-etp': {
    title: 'Create New ETP',
    TabComponent: CreateEtp,
  },
  'update-etp-constituents': {
    title: 'Update ETP Constituents',
    TabComponent: UpdateEtpConstituents,
  },
  'update-etp': {
    title: 'Update ETP',
    TabComponent: UpdateEtp,
  },
  'task-runner': {
    title: 'Task Runner',
    TabComponent: TaskRunner,
  },
  documents: {
    title: 'Documents',
    TabComponent: DocumentsUpload,
  },
  instrumentControls: {
    title: 'Controls',
    TabComponent: InstrumentControls,
  },
  apWallets: {
    title: 'AP Wallets',
    TabComponent: WalletUpdater,
  },
  'invite-user': {
    title: 'Invite/Remove user',
    TabComponent: UserInvite,
  },
  'audit-log': {
    title: 'Audit Log',
    TabComponent: AuditLogViewer,
  },
  'interest-product': {
    title: 'Interest Product',
    TabComponent: InterestProduct,
  },
  'inverse-rebalance': {
    title: 'Inverse Rebalance',
    TabComponent: InverseRebalance,
  },
  securitiesLending: {
    title: 'Securities Lending',
    TabComponent: SecuritiesLending,
  },
  contactList: {
    title: 'Contact List',
    TabComponent: ContactList,
  },
  'product-ledger': {
    TabComponent: ProductLedger,
  }
};

const Admin = ({ tab, subtab, toTab }) => {
  useEffect(() => {
    storeServices.crypto.find({ query: { skipAssociations: true } });
  }, []);

  const ActiveTabComponent = subtabMap[subtab].TabComponent;
  return (
    <StyledAdminPage>
      <Tab.Container id="admin-page" defaultActiveKey={subtab}>
        <Container>
          <StyledTitle>{subtabMap[subtab].title}</StyledTitle>
          <ActiveTabComponent />
        </Container>
      </Tab.Container>
    </StyledAdminPage>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
