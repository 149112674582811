import React, { useEffect, useState } from 'react';
import feathersClient from 'feathers/client';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import { connect } from 'react-redux';
import { Card, Container, Table } from 'react-bootstrap/esm/index';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import CardHeaderContent from 'shared/CardHeaderContent';
import ChartIcon from 'assets/icons/chart-bar.svg';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
});

const CollateralCheck = ({ activeInstrument }) => {
  const ticker = activeInstrument && activeInstrument.crypto[0].ticker;
  const [collateral, setCollateral] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCollateral, setHasCollateral] = useState(false);
  /**
   * @param {Array} data
   */
  const processLoanCollateralData = data => {
    const formatNumber = number =>
      isNaN(number)
        ? 0
        : Intl.NumberFormat('en').format(parseFloat(number).toFixed(4));

    const processData = data.reduce((accumulator, current, index) => {
      let localData = [];
      const currentIndex = {
        label: '#',
        value: index + 1,
      };
      const ticker = {
        label: 'Ticker',
        value: current.ticker,
      };

      const collaterals = current?.collateral
        .map(x => [
          {
            label: `Amount of ${x.ticker}`,
            value: formatNumber(x.balance),
          },
          {
            label: `${x.ticker} Value ($)`,
            value: `$${formatNumber(x.value)}`,
          },
        ])
        .flat();

      const usdTotalValue = {
        label: 'Total USD value',
        value: `$${formatNumber(current.totalCollateralValue)}`,
      };

      const collateralToLoan = {
        label: 'Loan To Value',
        value: `${formatNumber(current.collateralValueToLoanValue)}%`,
      };

      localData = [
        currentIndex,
        ticker,
        ...collaterals,
        usdTotalValue,
        collateralToLoan,
      ];
      accumulator.push(localData);
      return accumulator;
    }, []);

    return processData;
  };

  useEffect(() => {
    const getLoans = async () => {
      setIsLoading(true);
      const loans = await feathersClient.service('tasks').create(
        {},
        {
          query: {
            task: 'loanCollateral',
          },
        }
      );
      const localCollateral = loans.BlockFi;
      setCollateral(
        processLoanCollateralData(
          ticker
            ? localCollateral.filter(x => x.ticker === ticker)
            : localCollateral
        )
      );
      setIsLoading(false);
      setHasCollateral(
        ticker
          ? localCollateral.filter(x => x.ticker === ticker).length > 0
          : localCollateral.length > 0
      );
    };
    getLoans();
  }, [ticker]);

  return (
    <>
      <Container>
        <div className="mb-4"></div>
        {isLoading && <Loading />}
        {!hasCollateral && !isLoading && <NoResults />}
        {hasCollateral && !isLoading && (
          <>
            <Card className="my-5">
              <Card.Header>
                <CardHeaderContent
                  iconUrl={ChartIcon}
                  title={`Collateral Records`}
                ></CardHeaderContent>
              </Card.Header>
              <Card.Body>
                <div className="overflow-auto">
                  <Table>
                    <thead>
                      <tr>
                        {collateral.flat().map((data, i) => {
                          if (ticker && i === 1) return undefined;
                          return <th key={i}>{data.label}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {collateral.map((x, index) => (
                        <tr key={index}>
                          {x.map((data, i) => (
                            <td key={i}>{data.value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default connect(mapStateToProps)(CollateralCheck);
