import React            from 'react';
import BinanceIcon      from 'assets/icons/companies/binance.svg';
import BlockFiIcon      from 'assets/icons/companies/blockfi.svg';
import CoinbaseIcon     from 'assets/icons/companies/coinbase.svg';
import TesseractIcon    from 'assets/icons/companies/tesseract.svg';
import AmunIcon         from 'assets/icons/amun.svg';
import QuestionMarkIcon from 'assets/icons/questionmark.svg';

const CompanyIcon = ({companyName, height=18, classString="mx-1", displayQuestionMarkOnNull=false}) => {
  const getCompanyIcon = (companyName) => {
    switch (companyName) {
      case 'Amun':
        return AmunIcon;
      case 'Binance':
        return BinanceIcon;
      case 'BlockFi':
        return BlockFiIcon;
      case 'Coinbase':
        return CoinbaseIcon;
      case 'Tesseract':
        return TesseractIcon;
      default:
        if (!displayQuestionMarkOnNull) return false;
        return QuestionMarkIcon;
    }
  };

  let thisCompanyIcon = getCompanyIcon(companyName);

  return thisCompanyIcon ? (<img src={thisCompanyIcon} alt={companyName} className={classString} height={height} />) : null;
}

export default CompanyIcon;
