import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Row , Col } from 'react-bootstrap';
import feathersClient from 'feathers/client';
import groupBy from 'lodash/groupBy';

import Loading from 'shared/Loading';

import { toInstrumentCurrency, formatEightDecimals } from 'common/helpers/formatNumbers';

import BinanceIcon from 'assets/icons/companies/binance.svg';
import BlockFiIcon from 'assets/icons/companies/blockfi.svg';
import CoinbaseIcon from 'assets/icons/companies/coinbase.svg';
import TesseractIcon from 'assets/icons/companies/tesseract.svg';
import CheckCircleGreenIcon from 'assets/icons/check-circle-green.svg';
import WarningTriangleOrangeIcon from 'assets/icons/warning-triangle-orange.svg';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import './style.scss';

const CTL_THRESHOLD = 1;
const getCompanyIcon = (companyName) => {
  switch (companyName) {
    case 'Binance':
      return BinanceIcon;
    case 'BlockFi':
      return BlockFiIcon;
    case 'Coinbase':
      return CoinbaseIcon;
    case 'Tesseract':
      return TesseractIcon;
    default:
      return null;
  }
}

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
})

const Collaterals = ({ activeInstrument }) => {
  const [aggregateCollateralsData, setAggregateCollateralsData] = useState([]);
  const [aggregateLoansData, setAggregateLoansData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeRates, setExchangeRates] = useState({});

  useEffect(() => {
    async function getCollaterals() {
      setIsLoading(true);
      const { data: collateralValueData } = await feathersClient.service('interestInstrumentCollateralValue')
        .find({
          query: {
            instrumentId: activeInstrument.id,
            $limit: 1000,
            latest: true
          }
        });
      const { outstandingLoans: loanValueData, instrumentExchangeRates } = await feathersClient.service('interestInstrument/summaryOfOutstandingLoansService').get(activeInstrument.id, {
        query: {
          ignoreZeroDay: true,
          instrumentCurrency: activeInstrument.currency  // this tells backend to include relevant currency exchange rates
        }
      });
      
      setIsLoading(false);
      const formattedLoanValues = groupBy(loanValueData, (i) => i.counterpartyName);
      const formattedCollateralValues = groupBy(collateralValueData, (i) => i.counterpartyName);
      setExchangeRates(instrumentExchangeRates);
      setAggregateLoansData(formattedLoanValues);
      setAggregateCollateralsData(formattedCollateralValues);
    }
    if (activeInstrument) {
      getCollaterals();
    }
  }, [activeInstrument]);
  
  const getTotalLoanBalance = (counterparty, instrumentCurrency) => {
    const counterpartyLoans = aggregateLoansData[counterparty];
    if (counterpartyLoans) {
      return counterpartyLoans
        .reduce((prev, curr) => {
          const currencyPair = curr.currency + '-' + instrumentCurrency;
          const exchangeRate = exchangeRates[currencyPair] ? exchangeRates[currencyPair] : 1;
          return parseFloat(prev) + (parseFloat(curr.amount) * parseFloat(exchangeRate));
        }, 0);
    }
    return 0;
  }

  const renderCollateralValues = () => {
    const collateralCards = [];
    for (const counterparty in aggregateCollateralsData) {
      const collaterals = aggregateCollateralsData[counterparty];
      const instrumentCurrency = collaterals[0].instrumentCurrency;
      const totalLoanBalance = getTotalLoanBalance(counterparty, instrumentCurrency);
      const totalCollateralBalance = collaterals.reduce((prev, curr) => {
        return parseFloat(prev) + parseFloat(curr.value * curr.FXRate);
      }, 0);
      let collateralToLoanRatio = parseFloat(totalCollateralBalance) / parseFloat(totalLoanBalance);
      if (!isFinite(collateralToLoanRatio))
        collateralToLoanRatio = 0;
      // render rows for each crypto, and for each currency in crypto
      const tableRows = collaterals.map((collateral, index) => {
        const cryptoTicker = collateral.cryptoTicker;
        const balance = formatEightDecimals(collateral.balance);
        const value = parseFloat(collateral.value);
        return (
          <tr key={index}>
            <td>{balance} {cryptoTicker}</td>
            <td>{toInstrumentCurrency(value, collateral.currency)}</td>
            <td>{collateral.currency}</td>
          </tr>
        );
      });
      
      collateralCards.push(
        <Card className="mt-3">
          <Row className="no-gutters">
            <Col lg={8} className="pr-4">
              <div className="pl-3 pt-3">
                <div className="header d-flex align-counterpartys-center">
                  <span>
                    <img src={getCompanyIcon(counterparty)} alt="" className="mr-3" />
                  </span>
                  <h4>
                    <strong>{collaterals[0].counterpartyName}</strong>
                  </h4>
                </div>
              </div>
              <Card.Body>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Collateral Balance</th>
                      <th>Collateral In Currency</th>
                      <th>Collateral Currency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableRows}
                  </tbody>
                </Table>
              </Card.Body>
            </Col>
            <Col lg={4} className="card-right-sidebar p-4">
              <div>
                <span className="text-small text-muted">
                  Total Collateral {instrumentCurrency}
                </span>
                <span className="text-large d-block">
                  {toInstrumentCurrency(totalCollateralBalance, instrumentCurrency)}
                </span>
              </div>
              <hr />
              <div className="right-col-mid-counterparty">
                <span className="text-small text-muted">
                  Loan Balance {instrumentCurrency}
                </span>
                <div className="flex">
                  <span className="text-large mr-4">
                    {toInstrumentCurrency(totalLoanBalance, instrumentCurrency)}
                  </span>
                </div>
              </div>
              <hr />              
              <div>
                <span className="text-small text-muted">
                  Collateral to Loan
                </span>
                {collateralToLoanRatio > CTL_THRESHOLD ? (
                  <span className="text-large d-flex align-counterpartys-center text-medium-green">
                    {(collateralToLoanRatio * 100).toFixed(2)}%
                    <span>
                      <img src={CheckCircleGreenIcon} alt="normal" className="ml-2 text-medium-green" />
                    </span>
                  </span>) : (
                  <span className="text-large d-flex align-counterpartys-center text-dark-yellow">
                    {(collateralToLoanRatio * 100).toFixed(2)}%
                    <span>
                      <img src={WarningTriangleOrangeIcon} alt="warning" className="ml-2 text-dark-yellow" />
                    </span>
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      );
    }
    return collateralCards;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="mt-5">
          {renderCollateralValues()}
        </section>
      )}
    </>
  )
}

export default connect(mapStateToProps)(Collaterals);
