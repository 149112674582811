import React from 'react';
import { useFlags } from "launchdarkly-react-client-sdk";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';

import { StyledTaskRunner } from '../style';

import {
  runTask as runAdminTask,
} from '../api';

import GenerateIssAndFtTask from './components/GenerateIssAndFtTask.js'
import FetchCurrenciesForPcfTask from './components/FetchCurrenciesForPcfTask.js'
import FetchKeysPricesTask from './components/FetchKeysPricesTask.js'
import FetchKeysWeightsTask from './components/FetchKeysWeightsTask.js'
import FetchVinterIndexPricesTask from './components/FetchVinterIndexPricesTask'
import FetchVinterIndexPricesByInstrument from './components/FetchVinterIndexPricesByInstrument'
import FetchVinterIndexWeightsTask from './components/FetchVinterIndexWeightsTask'
import FetchVinterIndexWeightsByInstrument from './components/FetchVinterIndexWeightsByInstrument'
import FetchSingleTrackerPricesTask from './components/FetchSingleTrackerPricesTask.js'
import ReplenishBullionHoldingsTask from './components/ReplenishBullionHoldings';
import RunPcfComparisonTask from './components/RunPcfComparisonTask.js'
import RunInterestInstrumentLoanDailyCalcTask from './components/RunInterestInstrumentLoanDailyCalcTask.js'
import TriggerInternalPcfRunTask from './components/TriggerInternalPcfRunTask.js'
import TriggerSyntheticPcfCalculationTask from './components/TriggerSyntheticPcfCalculationTask.js'
import RunApOrdersTask from './components/RunApOrdersTask.js'
import AddOrdersToBooksTask from './components/AddOrdersToBooksTask.js'
import AddInverseBorrowsTask from './components/AddInverseBorrowsTask.js'
import BankFrickTask from './components/BankFrickTask.js'
import UpdateInternalPcfTask from './components/UpdateInternalPcfTask.js'
import FetchNetCollateralForInversesTask from './components/FetchNetCollateralForInversesTask.js'
import CheckLoanCollateralTask from './components/CheckLoanCollateralTask.js'
import CheckLendingRatesTask from './components/CheckLendingRatesTask.js'
import FetchCustodyBalancesTaskByTicker from './components/FetchCustodyBalancesTaskByTicker.js'
import FetchCustodyBalancesTask from './components/FetchCustodyBalancesTask.js'
import GenerateFactsheetTask from './components/GenerateFactsheetTask';
import GenerateRedirectTask from './components/GenerateRedirectTask';
import FetchFundAccountantNavTask from './components/FetchFundAccountantNav';
import TriggerCalculateClosingNavTask from './components/TriggerCalculateNavTask';
import FetchInstrumentBalancesFromExplorers from './components/FetchInstrumentBalancesFromExplorers';
import DownloadMvisFilesTask from './components/DownloadMvisIndexTask';
import DownloadSAndPIndexTask from './components/DownloadSAndPIndexTask';
import GenerateAndSendBloombergFile from './components/GenerateAndSendBloombergFile';
import TriggerRebalanceCalculations from './components/TriggerRebalanceCalculations';
import GenerateKneipKidXlsxTask from './components/GenerateKneipKidXlsxTask';
import TriggerStakingYieldAndLiquidityCalculations from './components/TriggerStakingYieldAndLiquidityCalculations';
import { COMPANY_IDS } from 'common/constants';
import FetchSingleTrackerPricesMarketCloseTask from './components/FetchSingleTrackerPricesMarketCloseTask';

const runTask = (task, setIsTaskRunning, payload) => {  
  setIsTaskRunning(true)
  toast.warning(`Your ${task} task is running `)
  runAdminTask(task, payload)
    .then((response) => {
      setIsTaskRunning(false)
      if (response.error) {
        toast.warning(`${response.error}\nDetails: ${JSON.stringify(response.data)}`);
      } else if (response.backgroundTask) {
        toast.warning(response.backgroundTask);
      } else {
        toast.success(`Your ${task} task was successful! `)
      }
    })
    .catch((error) => {
      setIsTaskRunning(false)
      toast.error(`Oops, your ${task} task did not complete: ${error.message}`)
      console.log('Error: ', error) // for investigating "validation error"
    });
};

const TaskRunner = () => {
  const {onyx557DeprecateCustodyBalance} = useFlags();
  return (
    <StyledTaskRunner>
      <Card className='task-runner-section'>
        <div className='section-title'>Documents</div>
        <GenerateRedirectTask />
        <GenerateIssAndFtTask />
        <GenerateFactsheetTask />
        <ReplenishBullionHoldingsTask  runTask={runTask} />
        <GenerateAndSendBloombergFile />
        <GenerateKneipKidXlsxTask />
      </Card>
      <Card className='task-runner-section'>
        <div className='section-title'>Prices, Indices, PCF</div>
        <FetchFundAccountantNavTask  runTask={runTask} />
        <RunPcfComparisonTask />
        {!onyx557DeprecateCustodyBalance && <TriggerInternalPcfRunTask runTask={runTask} />}
        <TriggerCalculateClosingNavTask runTask={runTask} />
        <TriggerRebalanceCalculations runTask={runTask} />
        <TriggerSyntheticPcfCalculationTask runTask={runTask} />
        <Row className="d-flex flex-wrap">
          <Col lg={4} className="d-flex flex-column">
            <FetchCurrenciesForPcfTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchKeysPricesTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchKeysWeightsTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <DownloadMvisFilesTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <DownloadSAndPIndexTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchSingleTrackerPricesTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchSingleTrackerPricesMarketCloseTask runTask={runTask} />
          </Col>
        </Row>
        <Row>
          <Col lg={8} className="d-flex flex-column">
            <FetchVinterIndexPricesByInstrument runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchVinterIndexPricesTask runTask={runTask} />
          </Col>
        </Row>
        <Row>
          <Col lg={8} className="d-flex flex-column">
            <FetchVinterIndexWeightsByInstrument runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchVinterIndexWeightsTask runTask={runTask} />
          </Col>
        </Row>
        <Row className="d-flex flex-wrap">
          <Col lg={4} className="d-flex flex-column">
            <RunInterestInstrumentLoanDailyCalcTask runTask={runTask} />
          </Col>
        </Row>
      </Card>
      <Card className='task-runner-section'>
        <div className='section-title'>Orders</div>
        <Row className="d-flex flex-wrap">
          <Col lg={4} className="d-flex flex-column">
            <RunApOrdersTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <AddOrdersToBooksTask runTask={runTask} />
          </Col>
        </Row>
      </Card>
      <Card className='task-runner-section'>
        <div className='section-title'>Staking Instruments</div>
        <Row className="d-flex flex-wrap">
          <Col lg={4} className="d-flex flex-column">
            <TriggerStakingYieldAndLiquidityCalculations runTask={runTask} />
          </Col>
        </Row>
      </Card>
      <Card className='task-runner-section'>
        <div className='section-title'>Inverse and loans</div>
        <Row className="d-flex flex-wrap">
          <Col lg={4} className="d-flex flex-column">
            <AddInverseBorrowsTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <BankFrickTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <UpdateInternalPcfTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchNetCollateralForInversesTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <CheckLoanCollateralTask runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <CheckLendingRatesTask runTask={runTask} />
          </Col>
        </Row>
      </Card>
      <Card className='task-runner-section'>
        <div className='section-title'>Custody Balances</div>
        <FetchCustodyBalancesTaskByTicker custodianBalancesTaskName="coinbase" custodianBalancesTaskId={COMPANY_IDS.COINBASE} runTask={runTask} />
        <FetchCustodyBalancesTaskByTicker custodianBalancesTaskName="copper" custodianBalancesTaskId={COMPANY_IDS.COPPER} runTask={runTask} />
        <Row className="d-flex flex-wrap">
          <Col lg={4} className="d-flex flex-column">
            <FetchCustodyBalancesTask custodianBalancesTaskName="coinbase" runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchCustodyBalancesTask custodianBalancesTaskName="copper" runTask={runTask} />
          </Col>
          <Col lg={4} className="d-flex flex-column">
            <FetchInstrumentBalancesFromExplorers ticker="ABBA" />
          </Col>
        </Row>
      </Card>
    </StyledTaskRunner>
  );
};

export default TaskRunner;
