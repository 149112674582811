/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import { keyBy, mapValues } from 'lodash';

// api, store
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import * as api from '../api';

// components
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import IndexWeightsTable from './components/IndexWeightsTable';
import RebalanceTable from './components/RebalanceTable';
import TableHeader from './components/TableHeader';
import TableFooter from './components/TableFooter';
import RebalanceDetails from './components/RebalanceDetails';
import NoResults from 'shared/NoResults';
import Loading from 'shared/Loading';

// utils, helpers
import { getTradeStrategy } from 'utils/tradeStrategy';
import { compareNumbers } from 'utils/numbers';
import get from 'lodash/get';
import { groupWeightsByUnderlying } from './helpers/groupWeightsByUnderlying';
import { every } from 'lodash';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  currentUser: state.session.data.user,
});

const renderRebalanceTable = ({
  activeInstrument,
  handleOnChange,
  handleOnClickApproval,
  loading,
  rebalances,
  weights,
  selectedDate,
  rebalanceDates,
  updating,
  isExecutable,
  tradeWasExecuted,
  refreshRebalanceValues,
  isRebalanceToday
}) => {
  const totalActualPostWeight = rebalances.reduce((totalWeights, rebalance) => {
    totalWeights += Number(rebalance.actualPostWeight);
    return totalWeights;
  }, 0);

  const rebalanceDatesSet = new Set(rebalanceDates);
  const isApproved = rebalances.find(rebalance => rebalance.approvedAt)
  let isReadyForApproval = rebalanceDatesSet.has(selectedDate) && !isApproved && !updating && compareNumbers(totalActualPostWeight, 1, 4);
  const isEditable = !isApproved && !(formatDate(selectedDate, 'YYYY-MM-DD') < formatDate(new Date(), 'YYYY-MM-DD'))
  if (activeInstrument.indexProvider === "S&P Dow Jones Indices" && activeInstrument.rebalancingFrequency === "Daily") {
    isReadyForApproval = isReadyForApproval && every(weights, (weight) => weight.preWeight !== weight.postWeight)
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <TableHeader
        activeInstrument={activeInstrument}
        rebalances={rebalances}
        isExecutable={isExecutable}
        isUpdating={updating}
        tradeWasExecuted={tradeWasExecuted}
        onRefresh={refreshRebalanceValues}
        isRebalanceToday={isRebalanceToday}
      />
      <RebalanceTable
        onChange={handleOnChange}
        rebalances={rebalances}
        editable={isEditable}
      />
      <TableFooter
        onClickApproval={handleOnClickApproval}
        isReadyForApproval={isReadyForApproval}
        rebalances={rebalances}
        isExecutable={isExecutable}
        isUpdating={updating}
      />
    </>
  );
};

const renderWeightTable = ({
  activeInstrument,
  loading,
  isComputingRebalanceDates,
  setIsComputingRebalanceDates,
  rebalanceDates,
  weights,
  selectedDate,
  setSelectedDate,
  rebalances,
}) => {
  const rebalDates = rebalanceDates.map(
    date =>
      new Date(new Date(date).toLocaleDateString('en-US', { timeZone: 'CET' }))
  );
  const pastRebalances = rebalDates.filter(date => date <= new Date());
  const futureRebalances = rebalDates.filter(date => date > new Date());

  const highlightRebalances = [
    {
      'past-rebalance': pastRebalances
    },
    {
      'future-rebalance': futureRebalances
    }
  ];
  setTimeout(() => setIsComputingRebalanceDates(false), 1000)

  return loading || isComputingRebalanceDates ? (
    <Loading />
  ) : (
    <IndexWeightsTable
      activeInstrument={activeInstrument}
      rebalanceDates={highlightRebalances}
      weights={weights}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      rebalances={rebalances}
    />
  );
};

const Rebalance = ({ activeInstrument, currentUser }) => {
  const [rebalances, setRebalances] = useState([]);
  const orderedRebalances = Object.values(rebalances).sort((rebalanceA, rebalanceB) => {
    const tickerA = rebalanceA.crypto ? rebalanceA.crypto.ticker : rebalanceA.metal.ticker;
    const tickerB = rebalanceB.crypto ? rebalanceB.crypto.ticker : rebalanceB.metal.ticker;

    return tickerA.localeCompare(tickerB);
  })
  const [weights, setWeights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [rebalanceDates, setRebalanceDates] = useState([]);
  const [isComputingRebalanceDates, setIsComputingRebalanceDates] = useState(true)
  const [selectedDate, setSelectedDate] = useState(
    formatDate(new Date(), 'YYYY-MM-DD')
  );
  const [showRebalanceDetails, setShowRebalanceDetails] = useState(false);
  const [isExecutable, setIsExecutable] = useState(false);

  useEffect(() => {
    if (activeInstrument) {
      setLoading(true);
      setIsExecutable(instrumentIsExecutable(activeInstrument));
      api
        .fetchRebalance(activeInstrument.id, selectedDate)
        .then(rebalancesResponse => {
          setRebalances(keyBy(rebalancesResponse, 'underlyingId'));
          setLoading(false);
        });
    }
    return () => false;
  }, [activeInstrument, selectedDate]);


  useEffect(() => {
    if (activeInstrument && selectedDate) {
      setLoading(true);
      api
        .fetchIndexWeights(activeInstrument.id, new Date(selectedDate))
        .then(weightsResponse => {
          setWeights(groupWeightsByUnderlying(weightsResponse, selectedDate));
          setLoading(false);
        });
    }
    return () => false;
  }, [activeInstrument, selectedDate]);


  useEffect(() => {
    if (activeInstrument && selectedDate) {
      setLoading(true);
      api
        .fetchRebalanceDates(
          activeInstrument.id,
          new Date(selectedDate).getFullYear()
        )
        .then(rebalanceDateResponse => {
          setRebalanceDates(rebalanceDateResponse.data);
          setLoading(false);
        });
    }
    return () => false;
  }, [activeInstrument, selectedDate]);

  const handleOnChange = async (newUnderlyingValue, options) => {
    let tradeStrategy = getTradeStrategy(options.tradeStrategy);
    if (instrumentIsExecutable(activeInstrument)) tradeStrategy = getTradeStrategy(options.tradeStrategy || 'BlockFi');

    Object.keys(rebalances).forEach((rebalance) => delete rebalances[rebalance].actualTrade)
    try {
      const { data: newRebalanceInfo } = await api.updateRebalance(
        newUnderlyingValue.id,
        {
          patch: Object.values({ 
            ...rebalances,
            [newUnderlyingValue.underlyingId]: newUnderlyingValue,
          }),
          options: 
          {
            instrumentId: activeInstrument.id,
            tradeStrategy
          }
        },
      );

      if (options.saveAuditLog) {
        api.createAuditLogEntry({
          type: 'USER',
          description: {
            user: currentUser.email,
            action: `Rebalance Override ${activeInstrument.ticker}`,
            details: {
              underlyingTicker: newUnderlyingValue.crypto?.ticker || newUnderlyingValue.metal?.ticker,
              newActualTrade: options.rebalance.actualTrade,
              newRebalanceInfo: newRebalanceInfo,
              updatedAt: new Date(),
            },
          },
        });
      }
      const newRebalances = newRebalanceInfo.reduce((o, key) => ({ ...o, [key.underlyingId]: key}), {});
      setRebalances(newRebalances);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOnClickApproval = () => setShowRebalanceDetails(true);

  const instrumentIsExecutable = (instrument) => false; // isExecutable was built for BlockFi S&P execution workflow but now disable for all products.
  const fetchTradingDeskByName = (tradingDeskName) =>
    api.fetchCompaniesByRole('TRADING_DESK').then(companies => {
      const activeTradingDesks = companies
        .filter(company => get(company, 'extraData.isActiveTradingDesk', false))
        .filter(({ name }) => name === tradingDeskName);
        if (activeTradingDesks.length > 0) return activeTradingDesks[0];
        return false;
    });

  const cancelModal = () => {
    if(updating) return false;
    return setShowRebalanceDetails(false);
  };

  const refreshRebalanceValues = async () => {
    if (Object.keys(rebalances).length === 0) {
      setUpdating(true)
      return api.runTask('calculateNav', {
        valuationDate: selectedDate,
        instrumentId: activeInstrument.id
      })
      .then(() => api
        .fetchRebalance(activeInstrument.id, selectedDate)
        .then(rebalancesResponse => {
          setRebalances(keyBy(rebalancesResponse, 'underlyingId'));
          toast.success('Data refreshed');
        }))
      .catch((error) => toast.error(error.message))
      .finally(() => setUpdating(false))
    }

    if (!isExecutable) {
      return false;
    }
    setUpdating(true);
    try {
      api.updateRebalanceCalculation(activeInstrument.id, rebalances)
        .then(rebalancesResponse => {
          if (rebalancesResponse['error'] && rebalancesResponse['message']) {
            toast.error(rebalancesResponse['message']);
            return false;
          }
          rebalancesResponse.data.data.forEach((thisResponse) => {
            for (const key in thisResponse) {
              rebalances[thisResponse.underlyingId][key] = thisResponse[key];
            }
          });
          setRebalances(rebalances);
          toast.success('Rebalance refreshed');
        })
        .catch(error => {
          toast.error(`Error: ${error.message}`);
          setShowRebalanceDetails(false);
        })
        .finally(() => {
          setUpdating(false);
        });
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  const fetchPermittedTradeCryptos = () => ['BTC', 'ETH'];

  const fetchIsRebalanceToday = (rebalances) => (
    Object.values(rebalances).some(rebalance => {
      const permittedCryptos = fetchPermittedTradeCryptos();
      const leftoverThreshold = 0.000000001;
      return permittedCryptos.includes(rebalance.crypto?.ticker) && 
        (rebalance.postWeight - rebalance.preWeight > leftoverThreshold || rebalance.postWeight - rebalance.preWeight < -leftoverThreshold)
    })
  );

  const confirmApproval = async () => {
    const approvedAt = new Date();

    if (!isExecutable) {
      setShowRebalanceDetails(false);
    }
    setUpdating(true);
    try {
      if (isExecutable) {
        const tradeStrategy = 'BlockFi';
        fetchTradingDeskByName(tradeStrategy).then(tradingDesk => {
          if (!tradingDesk) {
            toast.error(`No active trading desk found matching name: ${tradeStrategy}`);
            setShowRebalanceDetails(false);
            setUpdating(false);
            return false;
          }
          const date = new Date();
          const singleTradeData = {
            tradingDeskId: tradingDesk.id,
            denominator: 'USD',
            instrumentId: activeInstrument.id,
            reason: activeInstrument.ticker + ' trade executed: ' + currentUser.email + ' - ' + date.toString(),
          };
          let tradedRebalance;
          const permittedCryptos = fetchPermittedTradeCryptos();
          for (const key in rebalances) {
            if (!permittedCryptos.includes(rebalances[key].crypto.ticker)) {
              if (rebalances[key].crypto.ticker === 'USDC') continue;
              toast.error(`Invalid crypto ticker(s)`);
              setShowRebalanceDetails(false);
              setUpdating(false);
              return false;
            }
            tradedRebalance = rebalances[key];
            let tradeAction;
            switch(rebalances[key].action) {
              case 'SEND':
                tradeAction = 'SELL';
                break;
              case 'RECEIVE':
                tradeAction = 'BUY';
                break;
              case 'NONE':
                setUpdating(false);
                setShowRebalanceDetails(false);
                toast.info(`No trades necessary since rebalance quantity has not changed from the previous trading day.`);
                return false;
              default:
                setUpdating(false);
                setShowRebalanceDetails(false);
                toast.info(`No trades executed`);
                return false;
            }
            singleTradeData['cryptoTicker'] = rebalances[key].crypto.ticker;
            singleTradeData['side'] = tradeAction;
            singleTradeData['quantity'] = Math.abs(Number(rebalances[key].actualTrade));
            singleTradeData['rebalanceDataId'] = rebalances[key].id;
            singleTradeData['underlyingId'] = rebalances[key].underlyingId;
          }
          if (singleTradeData['side'] && singleTradeData['cryptoTicker'] && singleTradeData['quantity']) {
            if (rebalances[singleTradeData.underlyingId].tradeId) {
              toast.info(`Trade already executed for ${singleTradeData['cryptoTicker']}`);
              setShowRebalanceDetails(false);
              setUpdating(false);
              return false;
            }
            // execute trade
            api.executeSingleRebalanceTrade(singleTradeData)
              .then((result) => {
                if (result[0].status === 'FILLED') {
                  toast.info(`Trade successfully executed`);
                  // create placeholder data for frontend components
                  const thisDate = new Date();
                  const tradePlaceHolder = {
                    action: result[0].side,
                    createdAt: thisDate.toString(),
                    from: result[0].from,
                    quantity: result[0].quantity.quantity,
                    quote: result[0].unitPrice.price,
                    to: result[0].to
                  };
                  rebalances[singleTradeData.underlyingId].trade = tradePlaceHolder;
                  rebalances[singleTradeData.underlyingId].tradeId = result[0].tradeId;
                  // upon successful trade, build rebalance object with execution price

                  const newRebalanceInfo = {
                    ...tradedRebalance,
                    'price': result[0].unitPrice.price,
                    'actualTrade': result[0].quantity.quantity
                  };
                  
                  if (singleTradeData['side'] === 'SELL') {
                    newRebalanceInfo['actualTrade'] = newRebalanceInfo['actualTrade'] * -1;
                  }
                  // trigger a recalculation of all final values and save to db
                  handleOnChange(newRebalanceInfo, { rebalance: tradedRebalance, tradeStrategy })
                  .then(() => {
                    const [newRebalanceInfoUSDC] = Object.values(rebalances)
                      .filter((rebalance) => rebalance.crypto.ticker === 'USDC');

                    newRebalanceInfoUSDC['actualTrade'] =  result[0].totalValue;

                    if (singleTradeData['side'] === 'BUY') {
                      newRebalanceInfoUSDC['actualTrade'] = newRebalanceInfoUSDC['actualTrade'] * -1;
                    }

                    handleOnChange(newRebalanceInfoUSDC, { rebalance: tradedRebalance, tradeStrategy })
                      .then((result) => {
                        // flag rebalance as approved
                        api.approveRebalance(activeInstrument.id, {
                          update: { approvedAt },
                          ids: Object.values(rebalances).map(rebalance => rebalance.id),
                        });
                      })
                  })
                  .catch(error => {
                    toast.error(`Error: ${error.message}`);
                    setShowRebalanceDetails(false);
                    setUpdating(false);
                  })
                  .finally(() => {
                    api.fetchRebalance(activeInstrument.id, selectedDate)
                    .then(rebalancesResponse => setRebalances(keyBy(rebalancesResponse, 'underlyingId')));
                    setShowRebalanceDetails(false);
                  });
                } else {
                  toast.error(`Errors: ${JSON.stringify(result[0].errors)}`);
                }
              })
              .catch(error => {
                toast.error(`Error: ${error.message}`);
                setShowRebalanceDetails(false);
                setUpdating(false);
              })
              .finally(() => {
                setUpdating(false);
              });
          }
        });
      } else {
        await api.approveRebalance(activeInstrument.id, {
          update: { approvedAt },
          ids: Object.values(rebalances).map(rebalance => rebalance.id),
        });
        toast.success(`Rebalance Confirmed`);
        setUpdating(false);
      }

      const rebalancesWithApproval = mapValues(rebalances, rebalance => ({
        ...rebalance,
        approvedAt
      }))

      setRebalances(rebalancesWithApproval)
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown filter={({isIndex}) => isIndex} />
      </div>

      {activeInstrument && (
        <Card>
          <Card.Body>
            {renderWeightTable({
              activeInstrument,
              loading,
              isComputingRebalanceDates,
              setIsComputingRebalanceDates,
              handleOnChange,
              handleOnClickApproval,
              rebalanceDates,
              weights: Object.values(weights),
              selectedDate,
              setSelectedDate,
              updating,
              rebalances: orderedRebalances
            })}
          </Card.Body>
        </Card>
      )}
      <div className="my-5" />
      {activeInstrument && (
        <Card>
          <Card.Body>
            {renderRebalanceTable({
              activeInstrument,
              loading,
              handleOnChange,
              handleOnClickApproval,
              rebalances: orderedRebalances,
              selectedDate,
              weights,
              rebalanceDates,
              updating,
              isExecutable,
              tradeWasExecuted: orderedRebalances.some(rebalance => !!rebalance.tradeId),
              refreshRebalanceValues,
              isRebalanceToday: fetchIsRebalanceToday(weights),
            })}
            {activeInstrument && !loading && rebalances === [] && <NoResults />}
          </Card.Body>
        </Card>
      )}

      <RebalanceDetails
        show={showRebalanceDetails}
        onCancel={cancelModal}
        onConfirm={confirmApproval}
        rebalances={orderedRebalances}
        isExecutable={isExecutable}
        isUpdating={updating}
        tradeWasExecuted={orderedRebalances.some(rebalance => !!rebalance.tradeId)}
        onRefresh={refreshRebalanceValues}
        isRebalanceToday={fetchIsRebalanceToday(weights)}
      />
    </>
  );
};

export default connect(mapStateToProps)(Rebalance);
