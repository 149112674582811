import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import feathersClient from 'feathers/client';

import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';

import BinanceIcon from 'assets/icons/companies/binance.svg';
import BlockFiIcon from 'assets/icons/companies/blockfi.svg';
import CoinbaseIcon from 'assets/icons/companies/coinbase.svg';
import TesseractIcon from 'assets/icons/companies/tesseract.svg';
import CheckCircleGreenIcon from 'assets/icons/check-circle-green.svg';
import WarningTriangleOrangeIcon from 'assets/icons/warning-triangle-orange.svg';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import './style.scss';

const LTV_THRESHOLD = 0.4975;

const getCompanyIcon = (companyName) => {
  switch (companyName) {
    case 'Binance':
      return BinanceIcon;
    case 'BlockFi':
      return BlockFiIcon;
    case 'Coinbase':
      return CoinbaseIcon;
    case 'Tesseract':
      return TesseractIcon;
    default:
      return null;
  }
}

const toUSD = (amount) => amount
  .toLocaleString('us-EN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state)
});

const Collaterals = ({ activeInstrument }) => {
  const [aggregateCollateralsData, setAggregateCollateralsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    feathersClient
      .service('amunLending')
      .find({
        query: {
          ...(!!activeInstrument && { instrumentId: activeInstrument.id }),
          aggregateCollateralsData: true,
        },
      })
      .then(data => {
        setIsLoading(false);
        setIsEmpty(data.length === 0);
        setAggregateCollateralsData(data);
      });
  }, [activeInstrument]);


  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isEmpty ? (
        <NoResults className="mb-5" />
      ) : (
        <>
          {aggregateCollateralsData.map(({
            companyName,
            loansWithCollateral,
            totalLoanUSD,
            totalCollateralUSD,
            loanToValueRatio
          }) => (
            <Card className="mt-4 custom-card lending-table" key={companyName}>
              <Row className="no-gutters">
                <Col lg={8} className="pr-4">
                  <Card.Header>
                    <div className="header d-flex align-items-center">
                      <img src={getCompanyIcon(companyName)} alt={companyName} className="mr-3" />
                      {companyName}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Collateral Balance</th>
                          <th>Collateral Balance USD</th>
                          <th>Loan Balance</th>
                          <th>Loan Balance USD</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loansWithCollateral.map(({
                          collateral,
                          collateralCurrency,
                          collateralUSD,
                          loan,
                          loanCurrency,
                          loanUSD
                        }, index) => (
                          <tr key={index}>
                            <td>{collateral} {collateralCurrency}</td>
                            <td>${toUSD(collateralUSD)}</td>
                            <td>{loan} {loanCurrency}</td>
                            <td>${toUSD(loanUSD)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Col>
                <Col lg={4} className="card-right-sidebar p-4">
                  <div>
                    <span className="text-small text-muted">
                      Total Collateral USD
                    </span>
                    <span className="text-large d-block">
                      ${toUSD(totalCollateralUSD)}
                    </span>
                  </div>
                  <div className="right-col-mid-item">
                    <span className="text-small text-muted">
                      Outstanding Loan USD
                    </span>
                    <div className="flex">
                      <span className="text-large mr-4">
                        ${toUSD(totalLoanUSD)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="text-small text-muted">
                      Loan to Value
                    </span>
                    {loanToValueRatio > LTV_THRESHOLD ? (
                      <span className="text-large d-flex align-items-center text-medium-green">
                        {(loanToValueRatio * 100).toFixed(2)}%
                        <img src={CheckCircleGreenIcon} alt="normal" className="ml-2 text-medium-green" />
                      </span>
                    ) : (
                      <span className="text-large d-flex align-items-center text-dark-yellow">
                        {(loanToValueRatio * 100).toFixed(2)}%
                        <img src={WarningTriangleOrangeIcon} alt="warning" className="ml-2 text-dark-yellow" />
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          ))}
        </>
      )}
    </>
  )
}

export default connect(mapStateToProps)(Collaterals);
